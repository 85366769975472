<template>
	<v-autocomplete
		
		outlined
		color="black"
		filled
		:id="id"
		:items="timeList"
		v-model="time"
		:placeholder="placeholder"
		hide-details
		item-color="black"
		
		v-on:change="emitValue()"
		:disabled="disabled"
	/>
</template>

<script>
import moment from "moment-timezone";
import MomentJS from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "timepicker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		id: {
			type: String,
			default: "start-time",
		},
		placeholder: {
			type: String,
			default: "Start Time",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
		minTime: {
			type: String,
			default: null,
		},
	},
	watch: {
		value: function () {
			this.init();
		},
		minTime: function () {
			/*  this.time = null; */
			this.emitValue();
			this.initTimeList();
		},
	},
	data() {
		return {
			time: null,
			timeList: [],
		};
	},
	methods: {
		init() {
			this.time = this.value;
		},
		emitValue() {
			this.$emit("input", this.time);
			this.$emit("change", this.time);
		},
		// initTimeList() {
		// 	this.timeList = [];
		// 	let startTime = moment().startOf("day");
		// 	let endTime = moment().endOf("day");
		// 	let minTime = this.minTime ? moment(`${moment().format("YYYY-MM-DD")} ${this.minTime}`) : null;
		// 	let value = startTime.format("hh:mm A");
		// 	while (startTime.isBefore(endTime)) {
		// 		if (startTime && startTime.isBefore(minTime)) {
		// 			this.timeList.push({ text: value, value, disabled: true });
		// 		} else {
		// 			this.timeList.push({ text: value, value, disabled: false });
		// 		}
		// 		value = startTime.add(30, "minutes").format("hh:mm A");
		// 	}
		// },
		initTimeList() {
    this.timeList = [];
    let startTime = MomentJS().startOf("day"); // 00:00
    let endTime = MomentJS().endOf("day");     // 23:59

    while (startTime.isBefore(endTime)) {
        let text = startTime.format("hh:mm A");  // 12-hour format with AM/PM
        let value = startTime.format("HH:mm");   // 24-hour format
        this.timeList.push({ text, value });
        startTime.add(30, "minutes");
    }
},
		/* initTimeList() {
      this.timeList = [];
      let startTime = moment().startOf("day");
      let endTime = moment().endOf("day");
      let minTime = this.minTime
        ? moment(`${moment().format("YYYY-MM-DD")} ${this.minTime}`)
        : null;
      while (startTime.isBefore(endTime)) {

        let value = startTime.add(30, "minutes").format("hh:mm A");
        if (startTime && startTime.isBefore(minTime)) {
          this.timeList.push({ text: value, value, disabled: true });
        } else {
          this.timeList.push({ text: value, value, disabled: false });
        }
      }
    }, */
	},
	mounted() {
		this.init();
		this.initTimeList();
	},
};
</script>
